<template>
  <div
    class="grid w-fit"
    :style="{
      '--fill-progress': +Number((props.value / props.stars) * 100).toFixed(2),
    }"
  >
    <div
      v-for="n in 2"
      :style="cssVar"
      class="stars-container z-1 row-start-1 col-start-1 flex flex-row flex-nowrap items-end gap-[.175em]"
      :class="{ 'ak-filled': n == 2 }"
    >
      <span
        class="text-[1em] leading-[1]"
        v-for="_idx in Number(props.stars ?? 5)"
      >
        <span v-if="n === 1" class="icon-star-outlined"></span>
        <span v-if="n === 2" class="icon-star"></span>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
interface StarsRatingProps {
  value: number;
  stars?: number;
  color?: string;
}

const props = withDefaults(defineProps<StarsRatingProps>(), {
  value: 0,
  stars: 5,
  color: "#fbc125",
});

const cssVar = computed(() => {
  return { "--fill-color": props.color };
});
</script>

<style scoped>
.stars-container {
  /* #00a1aa99 */
  /* --fill-color: #fbc125; */
  color: var(--fill-color, inherit);
}

.stars-container.ak-filled {
  background-image: linear-gradient(
    90deg,
    var(--fill-color, black) calc(1% * var(--fill-progress, 0)),
    transparent 0%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* -webkit-text-stroke-width: 1.5px; */
  -webkit-text-stroke-color: var(--fill-color, black);
}

.stars-container:not(.ak-filled) {
  z-index: 0;
}
</style>
